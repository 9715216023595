import { useCallback, useEffect, useMemo, useState } from 'react';
import { Analytics, AnalyticsBrowser } from '@june-so/analytics-next';
import useNullishValue from './useNullishValue';
import { COMPANIES, ROLES, ROOT, TEAMS, USERS } from '@/store/selectors';
import { useSet, useValue } from 'use-change';
import useMap from './useMap';

const writeKey = process.env.JUNE_KEY;

export function useJune() {
  const [analytics, setAnalytics] = useState<Analytics>();
  const currentUser = useNullishValue(USERS.DATA, useValue(USERS, 'me'));
  const currentCompany = useNullishValue(
    COMPANIES.DATA,
    useValue(COMPANIES, 'myCompany'),
  );
  const currentUserTeams = useMap(TEAMS.DATA, currentUser?.teams);
  const roles = useMap(ROLES.DATA, useValue(ROLES, 'ids'));
  const setTrack = useSet(ROOT, 'track');

  const isTeamLead = useMemo(() => {
    if (!currentUser) return false;
    return currentUserTeams.some(
      (team) => team.teamLeadUserId === currentUser.id,
    );
  }, [currentUser, currentUserTeams]);

  const track = useCallback(
    (eventName: string, properties: Record<string, unknown>) => {
      if (!currentUser || !currentCompany) return;

      try {
        void analytics?.track({
          type: 'track',
          userId: currentUser.id,
          event: eventName,
          properties,
          // Add the GROUP_ID here to track this event on behalf of the company
          context: {
            groupId: currentCompany.id,
            userAgent: window.navigator.userAgent,
          },
        });
      } catch (ex) {
        console.error('Ignored June tracking error', ex);
      }
    },
    [analytics, currentCompany, currentUser],
  );

  useEffect(() => {
    if (!writeKey) return;
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    void loadAnalytics();
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    void analytics?.identify(currentUser.id, {
      email: currentUser.email,
      // Optional
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      avatar_url: currentUser.profileImage,
      // Add anything else about the user here
      role: roles.find(({ id }) => id === currentUser.auth0Role)?.name,
      isTeamLead,
    });
  }, [analytics, currentUser, isTeamLead, roles]);

  useEffect(() => {
    if (!currentCompany) return;
    void analytics?.group(currentCompany.id, {
      name: currentCompany.companyName,
      // Optional
      avatar_url: currentCompany?.logo,
      // Add anything else about the company here
    });
  }, [analytics, currentCompany]);

  useEffect(() => {
    setTrack(track);
  }, [setTrack, track]);

  return { analytics, track };
}
