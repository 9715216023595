import { ReactNode, useCallback, useEffect } from 'react';
import Head from 'next/head';
import { Inter } from 'next/font/google';
import Sidebar from './Sidebar/Sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import Link from 'next/link';
import { useSet, useValue } from 'use-change';
import { CARTS, COMPANIES, ROOT, USERS } from '@/store/selectors';
import { Permission } from '@/types/index';
import Spinner from '@/martsUi/Loading/Spinner/Spinner';
import { useRouter } from 'next/router';
import NoCompanyError from './NoCompany/NoCompanyError';
import useNullishValue from '@/lib/useNullishValue';
import { useJune } from '@/lib/useJune';
import Button from '@/martsUi/Buttons/Button/Button';
import useCartProducts from '@/lib/useCartProducts';
import Icon from '@/martsUi/Theme/Icon/Icon';
import useQuery from '@/lib/useQuery';

const inter = Inter({ subsets: ['latin'] });

interface Props {
  className?: string;
  noTopContentPadding?: boolean;
  title: ReactNode;
  subtitle?: string;
  metaTitle?: string;
  permissionsRequired?: Permission[];
  onClickBack?: () => void;
  children: ReactNode;
  buttons?: ReactNode;
  above?: ReactNode;
  isLoading?: boolean;
  cartButton?: boolean;
}

export default function MartsPage({
  className,
  noTopContentPadding,
  title,
  subtitle,
  metaTitle,
  children,
  buttons,
  above,
  permissionsRequired,
  isLoading,
  onClickBack,
  cartButton,
}: Props) {
  const auth0Data = useAuth0();
  const {
    user,
    isLoading: isAuth0Loading,
    isAuthenticated,
    loginWithRedirect,
    error,
    logout,
  } = auth0Data;
  const setAuth0Data = useSet(ROOT, 'auth0Data');
  const [companyId = ''] = useQuery('companyId');
  const currentUser = useNullishValue(USERS.DATA, useValue(USERS, 'me'));
  const myPermissions = useValue(USERS, 'myPermissions');
  const myCompany = useValue(COMPANIES, 'myCompany');
  const { isReady, asPath } = useRouter();
  const { analytics } = useJune();

  const myCart = useValue(CARTS, 'myCart');
  const { quantities } = useCartProducts(myCart);
  const totalProductQuantity = Object.values(quantities).reduce(
    (a, quantity) => a + quantity,
    0,
  );
  // Close mobile menu on page change
  const router = useRouter();

  const onSetDroppedFiles = useSet(ROOT, 'droppedFiles');

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onSetDroppedFiles([...e.dataTransfer.files]);
    },
    [onSetDroppedFiles],
  );

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('mobileMenuOpen');
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (!isAuthenticated && !isAuth0Loading && isReady) {
      void loginWithRedirect();
    }
  }, [isAuthenticated, isAuth0Loading, isReady, loginWithRedirect]);

  useEffect(() => {
    setAuth0Data(auth0Data); // make it available from outside of the component
  }, [auth0Data, setAuth0Data]);

  useEffect(() => {
    void analytics?.page();
  }, [asPath, analytics]);

  if ((isLoading && !user) || !currentUser || !isReady) {
    return (
      <div role="status" className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div
        role="status"
        className="flex justify-center items-center h-full flex-col"
      >
        {String(error)}
        <br />
        <a
          className="underline cursor-pointer"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          Logout
        </a>
      </div>
    );
  }

  if (
    currentUser &&
    permissionsRequired &&
    !permissionsRequired.every((perm) => myPermissions?.includes(perm))
  ) {
    return (
      <div
        role="status"
        className="flex justify-center items-center h-full flex-col"
      >
        You don&apos;t have enough access rights to view this page.
        <br />
        <Link href="/" className="underline cursor-pointer">
          Back to home page
        </Link>
      </div>
    );
  }

  if (!myPermissions?.includes(Permission.SYSTEM_ADMIN) && !myCompany) {
    return <NoCompanyError />;
  }

  const metaTitleContent =
    metaTitle ?? (typeof title === 'string' ? title : '');

  return (
    <div
      onDrop={onDrop}
      onDragOver={onDragOver}
      className={`${inter.className} flex min-h-full`}
    >
      <style jsx>{`
        .jsx-main {
          margin-left: 216px;
          max-width: 1400px;
          padding-bottom: 80px;
        }
        .jsx-mobile-menu-toggle {
          display: none;
        }
        @media (max-width: 767px) {
          .jsx-main {
            margin-left: 0;
          }

          .jsx-content {
            padding: 24px;
          }

          .jsx-mobile-menu-toggle {
            display: block;
            margin-top: 7px;
            margin-right: 16px;
          }
        }
      `}</style>
      <Head>
        <title>Marts - {metaTitleContent}</title>
        <meta name="description" content={`Marts - ${metaTitleContent}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Sidebar />
      <main className="flex-1 jsx-main min-h-screen flex flex-col">
        {above}
        <div className="px-16 py-11 pt-8 jsx-content min-h-full flex flex-col">
          <div className={'mb-4'}>
            <h1 className="text-xl flex align-middle items-center min-h-10 h-10">
              <div
                className="jsx-mobile-menu-toggle"
                onClick={() =>
                  document
                    .getElementsByTagName('body')[0]
                    .classList.toggle('mobileMenuOpen')
                }
              >
                <Icon name="menu" />
              </div>
              {typeof onClickBack === 'function' && (
                <span
                  onClick={onClickBack}
                  className="w-8 h-8 cursor-pointer bg-bg rounded-full flex align-middle justify-center mr-4 mt-1 aspect-square"
                >
                  <Icon name="arrowLeft" className="text-light" />
                </span>
              )}
              <span
                className={`${
                  typeof title === 'string'
                    ? 'line-clamp-1' // If title is a string prevent overflow
                    : 'flex items-center' // If title is a component center it horizontally
                } mt-1 h-8`}
              >
                {title}
              </span>
              <div className="flex align-middle flex-1 justify-end">
                {buttons}
                {cartButton && (
                  <Link
                    href={`/cart?${new URLSearchParams({
                      companyId: companyId ?? myCompany,
                    })}`}
                  >
                    <Button color="transparent" className="relative">
                      <Icon name="shoppingCart" className="w-[20px]" />
                      {!!totalProductQuantity && (
                        <span className="bg-positive rounded-full text-sm text-white w-5 h-5 leading-5 absolute -top-1 right-1">
                          {totalProductQuantity}
                        </span>
                      )}
                    </Button>
                  </Link>
                )}
              </div>
            </h1>
            <div className={'text-text-light ml-12 mt-3.5'}>{subtitle}</div>
          </div>
          <section
            className={`${className ?? ''} ${
              !noTopContentPadding ? 'pt-10' : ''
            } min-h-full flex flex-col`}
          >
            {children}
          </section>
          {isLoading && (
            <div className={'flex justify-center my-6'}>
              <Spinner />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
