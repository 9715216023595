import NavLink from './Nav/NavLink';
import { useRouter } from 'next/router';
import { useSilent, useValue } from 'use-change';
import { COMPANIES, REQUESTS, USERS } from '@/store/selectors';
import CurrentUser from './User/CurrentUser';
import { useEffect } from 'react';
import useData from '@/lib/useData';
import { SideBarLogo } from '@/shared/MartsPage/Sidebar/Logo/SideBarLogo';

const Sidebar = () => {
  const { pathname } = useRouter();
  const activePath = pathname.replace(/\//g, ' ').trim().split(/\s/)[0] as
    | ''
    | 'insights'
    | 'store'
    | 'pricing'
    | 'catalogues'
    | 'inventory'
    | 'requests'
    | 'orders'
    | 'administration'
    | 'users'
    | 'companies';
  const isCompanyAdmin = useValue(USERS, 'isCompanyAdmin');
  const isServiceAdmin = useValue(USERS, 'isServiceAdmin');
  const isServiceAgent = useValue(USERS, 'isServiceAgent');
  const showOwnCompanyAdmin =
    (isCompanyAdmin && !isServiceAgent) || (isServiceAgent && isServiceAdmin);
  const isSomeonesTeamLead = useValue(USERS, 'isSomeonesTeamLead');
  const canApproveRequests = useValue(USERS, 'canApproveRequests');
  const myApproverRequestIds = useValue(REQUESTS, 'myApproverRequestIds');
  const requestsCounter = myApproverRequestIds.length;

  const myCompany = useValue(COMPANIES, 'myCompany');
  const loadAllUsers = useSilent(USERS, 'loadAllUsers');
  const userData = useData(USERS);
  const users = Object.values(userData);
  const newUser = useValue(USERS, 'newUser');
  const onboardingUserNeedsAttention = useValue(
    USERS,
    'onboardingUserNeedsAttention',
  );
  const offboardingUser = useValue(USERS, 'offboardingUser');
  const userAttentionCount =
    users.filter(newUser).length +
    users.filter(onboardingUserNeedsAttention).length +
    users.filter(offboardingUser).length;

  useEffect(() => {
    const fetchUsers = async () => {
      if (myCompany && showOwnCompanyAdmin) await loadAllUsers();
    };
    void fetchUsers();
  }, [loadAllUsers, myCompany, showOwnCompanyAdmin]);

  return (
    <aside className="pt-8 pb-4 px-3 jsx-sidebar border-border-light border-r flex justify-between flex-col fixed min-h-full">
      <style jsx>{`
        .jsx-sidebar {
          width: 216px;
        }
        .jsx-nav {
          margin-top: 72px;
        }
        .jsx-active {
          background: rgba(0, 0, 0, 0.06);
        }
        @media (max-width: 767px) {
          .jsx-sidebar {
            display: block;
            position: fixed;
            top: 82px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background: #fff;
            transform: translateX(-100%);
            transition: transform 0.4s;
            pointer-events: none;
            border: 0;
          }

          .jsx-nav-user {
            position: absolute;
            bottom: 80px;
            left: 16px;
            z-index: 1001;
          }
          .jsx-nav {
            margin-top: 0;
          }
        }
      `}</style>
      <style jsx global>
        {`
          @media (max-width: 767px) {
            body.mobileMenuOpen aside.jsx-sidebar {
              pointer-events: auto !important;
              transform: translateX(0%) !important;
            }
          }
        `}
      </style>
      <div>
        <SideBarLogo />
        <nav className="jsx-nav mx-0.5">
          <NavLink href="/" isActive={activePath === ''} iconName={'homeSmile'}>
            Home
          </NavLink>
          {showOwnCompanyAdmin && (
            <NavLink
              href="/insights"
              isActive={activePath === 'insights'}
              iconName={'barChart'}
            >
              Insights
            </NavLink>
          )}
          <NavLink
            href={
              isServiceAgent
                ? '/store/customer'
                : `/store?companyId=${myCompany}`
            }
            isActive={activePath === 'store'}
            iconName={'shoppingCart'}
          >
            Store
          </NavLink>
          {isServiceAgent && (
            <NavLink href="/pricing" isActive={activePath === 'pricing'}>
              Pricing
            </NavLink>
          )}
          {(isCompanyAdmin || isSomeonesTeamLead) && (
            <NavLink href="/catalogues" isActive={activePath === 'catalogues'}>
              Catalogues
            </NavLink>
          )}
          <NavLink href="/orders" isActive={activePath === 'orders'}>
            Orders
          </NavLink>
          {canApproveRequests && (
            <NavLink
              href="/requests"
              isActive={activePath === 'requests'}
              count={requestsCounter || undefined}
            >
              Requests
            </NavLink>
          )}
          {isServiceAgent ? (
            <NavLink
              href={'/companies'}
              isActive={activePath == 'companies'}
              iconName={'company'}
            >
              Companies
            </NavLink>
          ) : (
            showOwnCompanyAdmin && (
              <NavLink
                href={`/administration?companyId=${myCompany}`}
                isActive={activePath === 'administration'}
                iconName={'company'}
              >
                Company
              </NavLink>
            )
          )}
          {isCompanyAdmin && (
            <NavLink
              href="/users"
              isActive={activePath === 'users'}
              count={userAttentionCount > 0 ? userAttentionCount : undefined}
            >
              Users
            </NavLink>
          )}
          <NavLink
            href="/inventory"
            isActive={activePath === 'inventory'}
            iconName={'monitor'}
          >
            Assets
          </NavLink>
        </nav>
      </div>
      <CurrentUser />
    </aside>
  );
};

export default Sidebar;
