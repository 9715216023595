import { useRouter } from 'next/router';
import { useCallback } from 'react';
import qs from 'qs';

export default function useQuery<T extends string>(paramName: string) {
  const { push, query, pathname, asPath } = useRouter();
  // get value immediately, don't wait the router to initialise
  const parsed =
    typeof document !== 'undefined'
      ? qs.parse(document.location.search.slice(1))
      : query;
  const param = (parsed[paramName] as T | undefined) ?? undefined;

  const setParam = useCallback(
    (value: string) => {
      const params = qs.stringify(
        Object.fromEntries(
          Object.entries({
            ...query,
            [paramName]: value || undefined,
          }).sort(([a], [b]) => (a > b ? 1 : -1)),
        ),
      );

      const newPath = pathname + (params ? '?' : '') + params;

      if (asPath !== newPath) {
        void push(newPath);
      }
    },
    [asPath, paramName, pathname, push, query],
  );

  return [param, setParam] as const;
}
