import { useAuth0 } from '@auth0/auth0-react';
import { Listbox, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useValue } from 'use-change';
import useBooleanState from '@/lib/useBooleanState';
import useClickOutside from '@/lib/useClickOutside';
import useNullishValue from '@/lib/useNullishValue';
import { COMPANIES, USERS } from '@/store/selectors';
import { UserAvatar } from '@/shared/UserAvatar/UserAvatar';

const CurrentUser = () => {
  const me = useValue(USERS, 'me');
  const currentUser = useNullishValue(USERS.DATA, me);
  const myCompany = useNullishValue(
    COMPANIES.DATA,
    useValue(COMPANIES, 'myCompany'),
  );
  const { logout } = useAuth0();
  const { push } = useRouter();
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState(false);
  const options = [
    { label: 'Profile', value: 'profile' },
    { label: 'Log out', value: 'logout' },
  ];
  const onMenuOptionCLick = useCallback(
    (option: { label: string; value: string }) => {
      closeMenu();
      if (option.value === 'logout') {
        logout({ logoutParams: { returnTo: window.location.origin } });
      } else if (option.value === 'profile') {
        if (!me) return;
        void push('/users/' + me);
      }
    },
    [closeMenu, logout, me, push],
  );

  const ref = useClickOutside<HTMLDivElement>(closeMenu);

  if (!currentUser) return null;

  return (
    <div className="relative" ref={ref}>
      <div>
        <Listbox onChange={onMenuOptionCLick}>
          <Transition
            show={isMenuOpen}
            as={'div'}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="top-[-94px] absolute z-10 mt-2 w-full rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div className="overflow-auto max-h-60 text-base">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className="relative cursor-default select-none px-4 py-2 hover:bg-bg"
                    value={option}
                  >
                    {option.label}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
      <div
        className={
          'relative flex items-center p-1 cursor-pointer jsx-nav-user rounded' +
          (isMenuOpen ? ' bg-border' : '')
        }
        onClick={isMenuOpen ? closeMenu : openMenu}
        title="Click to log out"
      >
        <div>
          <UserAvatar id={currentUser.id} size={32} />
        </div>
        <div className="flex flex-col ml-2 relative">
          <div className="truncate max-w-full">{currentUser.firstName}</div>
          <div className="text-sm text-text-light">
            {myCompany?.companyName ?? <em>No company</em>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentUser;
