import NextImage from 'next/image';

import classNames from 'classnames';
import Icon from '../../Theme/Icon/Icon';
import { useEffect, useRef, useState } from 'react';
import { LoadingPlaceholder } from '../../Loading/LoadingPlaceholder/LoadingPlaceholder';
import useTimeout from '@/lib/useTimeout';
import { twMerge } from 'tailwind-merge';

interface ImageProps {
  src: string;
  width: number;
  height: number;
  alt: string;
  className?: string;
  imageClassName?: string;
  notAvailable?: boolean;
  onClick?: () => void;
}

export const Image = ({
  src,
  width,
  height,
  alt,
  className,
  imageClassName,
  notAvailable,
  onClick,
}: ImageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const refImgLoaded = useRef(false);
  const refLastSrc = useRef(src);
  const imageRef = useRef<HTMLImageElement>(null);

  const imageCdn = 'https://ik.imagekit.io/marts';

  const optimiseImage = ({
    src,
    width,
    height,
  }: {
    src: string;
    width: number;
    height: number;
  }) => {
    src = src.replace(
      'https://prod-marts-public.s3.eu-north-1.amazonaws.com',
      imageCdn,
    );
    src = src.replace(
      'https://staging-marts-public.s3.eu-north-1.amazonaws.com',
      imageCdn,
    );
    return src?.startsWith(imageCdn)
      ? `${src}?tr=w-${width},h-${height},c-at_max`
      : src ?? '';
  };

  const onLoad = () => {
    refImgLoaded.current = true;
    setIsLoading(false);
    setImageError(false);
  };

  //https://stackoverflow.com/questions/1977871/check-if-an-image-is-loaded-no-errors-with-jquery
  const IsImageOk = (img: HTMLImageElement | null) => {
    if (!img) {
      return false;
    }
    // During the onload event, IE correctly identifies any images that
    // weren’t downloaded as not complete. Others should too. Gecko-based
    // browsers act like NS4 in that they report this incorrectly.
    if (!img.complete) {
      return false;
    }
    // However, they do have two very useful properties: naturalWidth and
    // naturalHeight. These give the true size of the image. If it failed
    // to load, either of these should be zero.
    if (img.naturalWidth === 0) {
      return false;
    }
    // No other way of checking: assume it’s ok.
    return true;
  };

  useTimeout(
    () => {
      //onload event is sometimes not fired in chrome if image is fetched from cache,
      // so we check it with the isImageOk function to stop loading state
      if (IsImageOk(imageRef.current)) {
        onLoad();
      } else if (!refImgLoaded.current) {
        setIsLoading(true);
      }
    },
    500,
    [src, imageRef],
  ); // after 500ms show loading skeleton for image

  useEffect(() => {
    if (refLastSrc.current !== src) {
      refImgLoaded.current = false;
      refLastSrc.current = src;
      setImageError(false);
      setIsLoading(false);
    }
  }, [src]);

  const imageLoader = ({
    src,
    width: optimiseWidth,
    quality = 75,
  }: {
    src: string;
    width: number;
    quality?: number;
  }) => {
    if (optimiseWidth >= width * 2) {
      src = optimiseImage({
        src,
        width: width * 2,
        height: height * 2,
      });
    } else {
      src = optimiseImage({
        src,
        width: width,
        height: height,
      });
    }
    return src.startsWith(imageCdn)
      ? src
      : `/_next/image?url=${encodeURIComponent(
          src,
        )}&w=${optimiseWidth}&q=${quality}`;
  };

  const tailwindSize = `w-${width / 4} h-${height / 4}`;
  return (
    <div className={twMerge('relative flex-none ', className ?? '')}>
      {!imageError ? (
        <NextImage
          src={src}
          ref={imageRef}
          width={width}
          height={height}
          loader={imageLoader}
          className={twMerge(
            `object-contain ${classNames({
              'opacity-20': notAvailable,
              'opacity-0': isLoading,
            })}`,
            imageClassName ?? '',
          )}
          alt={alt}
          onLoad={onLoad}
          onError={() => {
            refImgLoaded.current = true;
            setIsLoading(false);
            setImageError(true);
          }}
          onClick={onClick}
        />
      ) : (
        <div className={`${tailwindSize}`} />
      )}
      {(notAvailable || imageError) && (
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center ${tailwindSize}`}
        >
          <Icon name="xClose" className={imageError ? 'opacity-50' : ''} />
        </div>
      )}
      {!(notAvailable || imageError) && isLoading && (
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center ${tailwindSize}`}
        >
          <LoadingPlaceholder className="!h-full !w-full !min-w-0 p-[20%]" />
        </div>
      )}
    </div>
  );
};
