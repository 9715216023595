import { omit } from 'lodash';
import { useMemo } from 'react';
import { useGet, useValue } from 'use-change';
import { RootStore } from '../store';

function useData<
  T extends RootStore[
    | 'offices'
    | 'users'
    | 'roles'
    | 'companies'
    | 'collections'
    | 'products'
    | 'productTypes'
    | 'productVariants'
    | 'medusaImages'
    | 'moneyAmounts'
    | 'margins'
    | 'equipmentDocuments'
    | 'lineItems'
    | 'directorySyncs'
    | 'requestItems'],
>(storeSlice: (store: RootStore) => T): Omit<T['data'], '__updatedTimes'> {
  const getData = useGet(storeSlice, 'data');

  const updatedTimes = useValue(getData(), '__updatedTimes');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => omit(getData(), '__updatedTimes'), [updatedTimes]);
}

export default useData;
