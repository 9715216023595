import { useEffect, useRef } from 'react';

/**
 *
 * @example
 * const ref = useClickOutside<HTMLDivElement>((evt: MouseEvent) => console.log('blah'))
 * <div ref={ref} />
 */

type ClickEvent = MouseEvent & { target: HTMLElement };

export default function useClickOutside<T extends HTMLElement>(
  callback: (evt: ClickEvent) => void,
) {
  const ref = useRef<T>(null);
  useEffect(() => {
    function handler(evt: MouseEvent) {
      const { target } = evt;
      if (
        ref.current &&
        target &&
        !ref.current.contains(target as HTMLElement)
      ) {
        callback(evt as ClickEvent);
      }
    }
    // Bind the event listener
    document.addEventListener('click', handler);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handler);
    };
  }, [callback, ref]);

  return ref;
}
