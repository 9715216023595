// works the same as useValue but accepts null as key and returns TYPE | undefined
import useChange, { type ReturnTuple, type StoreSlice } from 'use-change';

function useNullishValue<
  STORE,
  KEY extends keyof SLICE | null | undefined,
  SLICE = STORE,
>(
  storeSlice: StoreSlice<STORE, SLICE>,
  key: KEY,
): typeof key extends null | undefined
  ? ReturnTuple<SLICE[Exclude<KEY, null | undefined>] | undefined>[0]
  : ReturnTuple<SLICE[Exclude<KEY, null | undefined>]>[0] {
  // @ts-expect-error TODO
  return useChange<STORE, Exclude<KEY, null | undefined>, SLICE>(
    storeSlice,
    key as Exclude<KEY, null | undefined>,
  )[0];
}

export default useNullishValue;
