import { useValue } from 'use-change';

import { USERS } from '@/store/selectors';
import { User } from '@/types/index';
import { Image } from '@/martsUi/Components/Image/Image';
import Avvvatars from 'avvvatars-react';
import { ComponentProps, ReactNode } from 'react';

interface Props {
  id: User['id'];
  className?: ComponentProps<'div'>['className'];
  size?: number;
}

export const UserAvatar = ({ id, className, size = 28 }: Props) => {
  const {
    profileImage,
    firstName = '',
    lastName = '',
  } = useValue(USERS.DATA, id) ?? {};

  const name = `${firstName} ${lastName}`;

  if (!profileImage) {
    return (
      <UserAvatarWrapper className={className}>
        <Avvvatars value={name} size={size} />
      </UserAvatarWrapper>
    );
  }

  return (
    <UserAvatarWrapper className={className}>
      <Image
        src={profileImage}
        imageClassName={'max-w-none rounded-full aspect-square'}
        width={size}
        height={size}
        alt={`Profile image of ${name}`}
      />
    </UserAvatarWrapper>
  );
};

const UserAvatarWrapper = ({
  className,
  children,
}: {
  className?: ComponentProps<'div'>['className'];
  children: ReactNode;
}) => (
  <div className={className}>
    <div className={`shadow-avatar border-2 border-white rounded-full`}>
      {children}
    </div>
  </div>
);
