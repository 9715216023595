import { ComponentProps } from 'react';

interface CounterProps {
  count: number;
  className?: ComponentProps<'div'>['className'];
  status?: 'neutral' | 'negative';
}

export const Counter = ({
  count,
  className,
  status = 'neutral',
}: CounterProps) => {
  const statusToClasses: Record<string, string> = {
    neutral: 'bg-black text-white',
    negative: 'bg-negative-strong text-white',
  };
  return (
    <span
      className={`${statusToClasses[status]} rounded-full text-center text-sm min-w-[18px] px-2 h-5 inline-flex items-center ${className}`}
    >
      {count}
    </span>
  );
};
