import { useMemo } from 'react';
import {
  CARTS,
  LINE_ITEMS,
  PRODUCT_VARIANTS,
  PRODUCTS,
} from '@/store/selectors';
import { Cart, Product } from '@/types/index';
import useData from './useData';
import useNullishValue from './useNullishValue';
import { useSilent } from 'use-change';

export default function useCartProducts(cartId: Cart['id'] | null) {
  const lineItemsData = useData(LINE_ITEMS);
  const productVariantsData = useData(PRODUCT_VARIANTS);
  const productsData = useData(PRODUCTS);
  const cart = useNullishValue(CARTS.DATA, cartId);
  const getProductFromData = useSilent(PRODUCTS, 'getProductFromData');

  return useMemo(() => {
    if (!cart) {
      return { products: [], quantities: {} };
    }

    const products: Product[] = [];
    const quantities: Record<Product['id'], number> = {};

    cart.items.forEach((id) => {
      const item = lineItemsData[id];
      const variant = productVariantsData[item.variant_id];

      const product = getProductFromData(variant.product_id);
      if (product) {
        quantities[variant.product_id] = item.quantity;
        products.push(product);
      }
    });

    return { products, quantities };

    // .sort((a, b) => a.productId > b.productId ? -1 : 1)
  }, [cart, lineItemsData, productVariantsData, productsData]);
}
