import { useEffect, useRef } from 'react';

export default function useTimeout(
  callback: () => void,
  delay: number,
  observe: unknown[] = [],
) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(
    () => {
      // Don't schedule if no delay is specified.
      if (delay === null) {
        return;
      }

      const id = setTimeout(() => savedCallback.current(), delay);

      return () => clearTimeout(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay as unknown].concat(observe),
  );
}
