import { Skeleton } from 'antd';
import { ComponentProps, CSSProperties } from 'react';

interface LoadingPlaceholderProps {
  type?: 'block' | 'circle';
  height?: number;
  size?: number;
  className?: ComponentProps<'div'>['className'];
  count?: number;
}

export const LoadingPlaceholder = ({
  count = 1,
  ...props
}: LoadingPlaceholderProps) => {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <LoadingPlaceholderInner key={i} {...props} />
      ))}
    </>
  );
};

const LoadingPlaceholderInner = ({
  type = 'block',
  height,
  size,
  className,
}: LoadingPlaceholderProps) => {
  const style: CSSProperties = {};
  if (size) {
    style.width = `${size}px`;
    style.minWidth = `${size}px`;
    style.height = `${size}px`;
  } else if (height) style.height = `${height}px`;

  if (type === 'circle') {
    return (
      <Skeleton.Avatar
        active
        size={'small'}
        className={className ?? ''}
        style={style}
      />
    );
  }

  return (
    <Skeleton.Input
      active
      block={true}
      style={style}
      className={className ?? ''}
    />
  );
};
