import Button from '@/martsUi/Buttons/Button/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Image } from '@/martsUi/Components/Image/Image';

const NoCompanyError = () => {
  const { logout } = useAuth0();
  return (
    <div className="flex justify-center items-center text-center h-full flex-col">
      <Image
        src="/images/no-company-illustration.png"
        alt=""
        width={140}
        height={140}
      />
      <h1 className="text-xl leading-8 mt-6">
        Seems like your company
        <br />
        is not using Marts yet
      </h1>
      <p className="text-text-light mt-6">
        That’s a shame! Please sign up on the page beneath to get
        <br />
        access as fast as possible.
      </p>
      <Button
        className="cursor-pointer mt-6"
        color="secondary"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        Request access on homepage
      </Button>
    </div>
  );
};

export default NoCompanyError;
