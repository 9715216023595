import { useSilent, useValue } from 'use-change';
import { COMPANIES, USERS } from '@/store/selectors';
import useNullishValue from '@/lib/useNullishValue';
import Icon from '@/martsUi/Theme/Icon/Icon';
import { CompanyLogo } from '@/shared/CompanyLogo/CompanyLogo';

export const SideBarLogo = () => {
  const me = useSilent(USERS, 'me');
  const myCompany = useValue(COMPANIES, 'myCompany');
  const company = useNullishValue(COMPANIES.DATA, myCompany);

  if (!me || !company) return <div className={`h-9`} />;

  const isServiceProvider = company.customers && company.customers.length > 0;
  if (isServiceProvider) {
    return (
      <div className="mx-2 flex items-center gap-2">
        <CompanyLogo context={company.id} size={36} />
        <div className={'text-sm-heavy'}>{company.companyName}</div>
      </div>
    );
  }

  if (company.serviceProvider) {
    return (
      <div className="mx-2 flex items-center gap-2">
        <CompanyLogo context={company.serviceProvider} size={36} />
        <div className={'text-sm-heavy'}>
          {company.serviceProvider.companyName}
        </div>
      </div>
    );
  }

  return (
    <div className="mx-1.5">
      <Icon name={'marts'} className={'w-9'} />
    </div>
  );
};
