import { Image } from '@/martsUi/Components/Image/Image';
import { COMPANIES } from '@/store/selectors';
import Avvvatars from 'avvvatars-react';
import { LoadingPlaceholder } from '@/martsUi/Loading/LoadingPlaceholder/LoadingPlaceholder';
import useNullishValue from '@/lib/useNullishValue';

interface CompanyLogoProps {
  context:
    | {
        companyName: string;
        logo: string | null;
      }
    | string
    | undefined;
  size?: number;
}

export const CompanyLogo = ({ context, size = 36 }: CompanyLogoProps) => {
  const myCompanyId = typeof context === 'string' ? context : null;
  const myCompany = useNullishValue(COMPANIES.DATA, myCompanyId);
  const company = typeof context === 'string' ? myCompany : context;

  if (!company) {
    return (
      <div className={`w-[${size}px]`}>
        <LoadingPlaceholder size={size} />
      </div>
    );
  }

  if (!company.logo) {
    return (
      <Avvvatars
        style="shape"
        value={company.companyName}
        size={size}
        radius={12}
      />
    );
  }

  return (
    <Image
      src={company.logo}
      width={size}
      height={size}
      alt={company.companyName}
      imageClassName={'border border-border rounded-xl mx-auto aspect-square'}
    />
  );
};
